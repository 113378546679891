import * as React from "react";

type AsyncRouteComponent<TProps> = React.LazyExoticComponent<
  React.ComponentType<TProps>
>;

export function lazyRouteComponent<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is the only way to infer the props type
  T extends Record<string, any>,
  TKey extends keyof T = "default",
>(
  importer: () => Promise<T>,
  exportName?: TKey,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is the only way to infer the props type
): T[TKey] extends (props: infer TProps) => JSX.Element
  ? AsyncRouteComponent<TProps>
  : never {
  const lazyComp = React.lazy(async () => {
    const moduleExports = await importer();
    const comp = moduleExports[exportName ?? "default"];
    return {
      default: comp,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is the only way to infer the props type
  return lazyComp as any;
}
